<app-navbar></app-navbar>
<!-- <app-menu-fixed></app-menu-fixed> -->
<div class="lista-casamento">
  <div class="blocoInicial">
      <div class="title">
        <div class="container">
            <div class="row">
                <div class="text-center text-lista-casamento">
                  <h1 class="mobile-text">Lista de Presentes</h1>
                  <img class="florAzulMeio" src="assets\img\florMeioAzul.png" alt="">
                </div>
                <div class="agradecimento">
                  <h5>Criamos esse espaço para colocar listas de presente justa e variada para ajudar aqueles que querem presentear.</h5>
                  <p class="comCarinho">Com carinho, Andresa e Vinícius</p>
                </div>
            </div>
        </div>
      </div>
  </div>

  <div class="container">
    <div class="row">

      <div class="card col-3">
        <a ngxClipboard [cbContent]="'andresa3008@gmail.com'" [container]="container"  target="_blank">
          <div class="circle">
            <img class="imgPresente img" src="assets/img/home/listaPresente/pix.png" alt="">
          </div>
          <div #container class="content">
            <p>Na dúvida do presente, faça um pix.</p>
            <p>Chave: andresa3008@gmail.com Andresa Vasconcelos.</p>
            <button type="button" class="button" data-bs-dismiss="modal" (click)="showCopyMessage()">Copiar chave pix</button>
          </div>
        </a>
      </div>

      <div class="card col-3">
        <a href="https://www.amazon.com.br/hz/wishlist/ls/DA8BW305Y2J5?ref_=wl_share " target="_blank">
          <div class="circle">
            <img class="imgPresente pix" src="assets/img/home/listaPresente/amazon.png" alt="">
          </div>
          <div class="content">
            <p>Quem assina Amazon Prime tem direito a frete gratis e com entrega direto para nós.</p>
            <p>Endereço: Envio direto pela Amazon</p>
            <a href="https://www.amazon.com.br/hz/wishlist/ls/DA8BW305Y2J5?ref_=wl_share " target="_blank">Dar uma olhadinha</a>
          </div>
        </a>
      </div>

      <div class="card col-3">
        <a href="https://lista.camicado.com.br/be_e_de" target="_blank">
          <div class="circle">
            <img class="imgPresente" src="assets/img/home/listaPresente/camicado.png" alt="">
          </div>
          <div class="content">
            <p>A Camicado tem uma lista com peças lindas e com parcelamento sem juros na maioria das peças.</p>
            <p>Endereço: Envio direto pela Camicado</p>
            <a href="https://lista.camicado.com.br/be_e_de" target="_blank">Dar uma olhadinha</a>
          </div>
        </a>
      </div>

      <div class="card col-3">
        <a href="https://www.querodecasamento.com.br/lista-de-casamento/andresa-vinicius" target="_blank">
          <div class="circle">
            <img class="imgPresente" src="assets/img/home/listaPresente/magalu.png" alt="">
          </div>
          <div class="content">
            <p>Uma loja com muitas opções e também com entrega facil, rápida e parcelamento sem juros.</p>
            <p>Endereço: Envio direto pela Magalu</p>
            <a href="https://www.querodecasamento.com.br/lista-de-casamento/andresa-vinicius" target="_blank">Dar uma olhadinha</a>
          </div>
        </a>
      </div>
    </div>
  </div>

  <!-- Overlay e mensagem de notificação -->
  <div id="copyOverlay" class="copy-overlay">
    <div id="copyMessage" class="copy-message">Texto copiado!</div>
  </div>

</div>

